import { Toast } from "@/components/essentials/toast";
import { type ApolloError } from "@apollo/client";
import { type GraphQLError } from "graphql/error";
import { type GraphQLErrorCode } from "@/apollo/error-code";
import { type GraphQLSubErrorCode, NO_DETAILED_INFORMATION } from "@/apollo/sub-error-code";
import { isNonNullable } from "@/modules/utils/predicate";
import { type FC } from "react";
import { datadogLogs } from "@datadog/browser-logs";
import { graphQLErrorsTestId, networkErrorTestId } from "./const";

export interface ApolloErrorProps {
  readonly errors: ApolloError | undefined;
  readonly testid?: string;
}

const defaultMessageKey = "_";
type DefaultMessage = typeof defaultMessageKey;
const convertDefaultSubCode = (subCode: string): string =>
  subCode === NO_DETAILED_INFORMATION ? defaultMessageKey : subCode;

export const constGraphQLErrorMessage = {
  GRAPHQL_PARSE_FAILED: "エラーが発生しました。",
  GRAPHQL_VALIDATION_FAILED: "入力内容に不備があります。",
  BAD_USER_INPUT: {
    INVALID_TOKEN: null,
    INVALID_PASSWORD_INPUT:
      "パスワードの強度に問題があります。メールアドレスに類似する文字列がパスワードに含まれている可能性があります。",
    _: "入力内容に不備があります。",
    MFA_INCORRECT_CODE: "二段階認証コードが無効であるか、期限が切れています。",
    MFA_VERIFY_PASSWORD_EXPIRED: "パスワード認証の期限が切れています。",
    INVALID_RECOVERY_CODE: "リカバリコードが無効であるか、利用済みです。",
  },
  UNAUTHENTICATED: {
    FAILED_TO_SIGN_IN: "サインインに失敗しました。",
    LOCKED:
      "試行回数が上限を超えました。ユーザー保護のため、現在サインイン又は新規登録をすることはできません。しばらくしてからもう一度お試しください。",
  },
  FORBIDDEN: {
    MFA_ALREADY_REGISTERED: "既に二段階認証はオンです。",
    MFA_LOCKED:
      "試行回数が上限を超えました。ユーザー保護のため、現在サインイン又は新規登録をすることはできません。しばらくしてからもう一度お試しください。",
    _: "ご利用にはサインインが必要です。",
  },
  PERSISTED_QUERY_NOT_FOUND: "エラーが発生しました。",
  PERSISTED_QUERY_NOT_SUPPORTED: "エラーが発生しました。",
  INTERNAL_SERVER_ERROR: "エラーが発生しました。",
  NOT_FOUND: "エラーが発生しました。",
} as const;

// to type check
export const GraphQLErrorMessage: Record<
  GraphQLErrorCode,
  string | Partial<Record<GraphQLSubErrorCode | DefaultMessage, string | null>>
> = constGraphQLErrorMessage;

// to accept other string
type AcceptAnyCode = Record<string, string | Record<string, string | null>>;
export const ErrorMessage: AcceptAnyCode = GraphQLErrorMessage;

export const OTHER_ERROR = "予期せぬエラーが発生しました。";

export const NETWORK_ERROR = "ネットワークエラーが発生しました。";

export const getErrorMessage = ({ extensions: { code, subCode } }: GraphQLError): string | null => {
  /* eslint-disable security/detect-object-injection -- GraphQLError isn't user input */

  if (typeof code !== "string") {
    datadogLogs.logger.warn("unreachable code: the code is unexpected type at getErrorMessage", {
      typeofCode: typeof code,
    });
    return OTHER_ERROR;
  }

  const messageOrRecord = ErrorMessage[code];

  if (messageOrRecord === undefined) {
    datadogLogs.logger.warn("unreachable code: the code is not defined at getErrorMessage", {
      code,
    });
    return OTHER_ERROR;
  }

  // return message for code
  if (typeof messageOrRecord === "string") return messageOrRecord;

  if (typeof subCode !== "string") {
    datadogLogs.logger.warn("unreachable code: the sub code is unexpected type at getErrorMessage", {
      subCode,
    });
    return OTHER_ERROR;
  }

  const messageOrNull = messageOrRecord[convertDefaultSubCode(subCode)];

  if (messageOrNull === undefined) {
    datadogLogs.logger.warn("unreachable code: the sub code not defined at getErrorMessage", {
      subCode,
    });
    return OTHER_ERROR;
  }

  // message or null for the sub code
  return messageOrNull;

  /* eslint-enable security/detect-object-injection */
};

export const ApolloErrorToast: FC<ApolloErrorProps> = (props) => {
  const { errors } = props;
  if (errors === undefined) {
    return null;
  }

  const { graphQLErrors, networkError } = errors;

  if (networkError !== null) {
    datadogLogs.logger.error(
      `Network Error: errorMessage=${networkError.message} errorDetail=${JSON.stringify(networkError)}`
    );

    return (
      <Toast variant="error" data-testid={props.testid ?? networkErrorTestId}>
        {NETWORK_ERROR}
      </Toast>
    );
  }

  const shouldRenderErrorMessages = graphQLErrors.map(getErrorMessage).filter(isNonNullable);

  if (shouldRenderErrorMessages.length === 0) return null;

  return (
    <div data-testid={props.testid ?? graphQLErrorsTestId}>
      {shouldRenderErrorMessages.map((message, index) => (
        <Toast variant="error" key={index} data-testid={`${graphQLErrorsTestId}${index}`}>
          {message}
        </Toast>
      ))}
    </div>
  );
};
