import { type ReactNode, type ForwardedRef } from "react";
import { ListItem as ChakraListItem, type ListItemProps as ChakraListItemProps, forwardRef } from "@chakra-ui/react";

export interface ListItemProps extends ChakraListItemProps {
  readonly children?: ReactNode;
}

export const ListItem = forwardRef<ListItemProps, "li">(({ children, ...rest }, ref: ForwardedRef<HTMLLIElement>) => {
  return (
    // スタイルのバリエーションが増えたら https://chakra-ui.com/docs/styled-system/theming/component-style#styling-multipart-components を活用する
    <ChakraListItem fontSize="0.875rem" lineHeight="180%" ref={ref} {...rest}>
      {children}
    </ChakraListItem>
  );
});
