import { differenceInMonths, isBefore } from "date-fns";

const aYear: number = 12;

export const getAgeByBirthday = (
  birthday: Date | undefined,
  baseDate?: Date | undefined
): [year: number, month: number] | [year: undefined, month: undefined] => {
  if (baseDate === undefined || birthday === undefined) {
    return [undefined, undefined];
  }
  const diffMonth = differenceInMonths(baseDate, birthday);
  const ageOfYear = Math.trunc(diffMonth / aYear);
  const ageOfMonth = Math.abs(diffMonth % aYear);
  return [ageOfYear, ageOfMonth];
};

export const buildChildAge = (birthday?: Date, date?: Date): string => {
  if (date === undefined || birthday === undefined) return "不明";
  const [ageOfYear, ageOfMonth]: number[] | undefined[] = getAgeByBirthday(birthday, date);
  if (ageOfYear === undefined || ageOfMonth === undefined) {
    return "不明";
  }

  return `${isBefore(birthday, date) ? "" : "-"}${Math.abs(ageOfYear)}歳${ageOfMonth}か月`;
};
