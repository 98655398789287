import { Tag } from "@/components/essentials/tag";
import { Typography } from "@/components/essentials/typography";
import { SHIPMENT_TOY_STATUS_CONTINUATION } from "@/configs/uuids/shipments/shipment-toy/statuses";
import { type ShipmentToyStatus } from "@/gql/generated/graphql.gen";
import { Image } from "@chakra-ui/image";
import { Flex } from "@chakra-ui/layout";
import { type FlexProps, type ImageProps } from "@chakra-ui/react";
import { datadogLogs } from "@datadog/browser-logs";
import { useState, type FC } from "react";

export interface ToyImageProps extends FlexProps {
  readonly src: string | undefined;
  readonly name?: string;
  readonly toyStatus?: ShipmentToyStatus | null;
}

export const ToyImage: FC<ToyImageProps> = (props) => {
  const { src, name, borderRadius, objectFit, toyStatus, ...rest } = props;
  const imageBaseProp: ImageProps = { borderRadius, objectFit, bgColor: "white" };

  const [srcState, setSrcState] = useState<string | undefined>(src);

  return (
    <Flex justifyContent="center" bgColor="white" borderRadius={borderRadius} {...rest}>
      {srcState === undefined ? (
        <Image src="/images/noimage.png" alt="おもちゃ画像なし" {...imageBaseProp} />
      ) : (
        // TODO: Chakraのバージョンが上がったら、srcStateを削除してchakuraの機能でnoimageを表示する
        // chakra-ui/imageのfallbackStrategyをonErrorにしてfallbackSrcにNoImageを指定する。
        // 参考: https://github.com/chakra-ui/chakra-ui/commit/9081a8ae9243a32b71942d6a0b9ba5a2b3fb9fe6
        <Image
          src={srcState}
          onError={() => {
            datadogLogs.logger.error(`toy image load error, imageSrc=${src ?? ""}`);
            setSrcState(undefined);
          }}
          alt="おもちゃ画像"
          {...imageBaseProp}
        />
      )}
      {name !== undefined && (
        <Typography variant="display7" mt="0.5rem">
          {name}
        </Typography>
      )}
      {toyStatus?.uuid === SHIPMENT_TOY_STATUS_CONTINUATION && (
        <Tag colorScheme="theme.red" variant="solid" position="absolute" right="3px" bottom="4px">
          {toyStatus.name}
        </Tag>
      )}
    </Flex>
  );
};
