import { type BoxProps } from "@/components/essentials/box";
import { responsiveSquareBoxProps, responsiveSquareImageProps } from "./const";
import { type ToyImageProps } from "./ToyImage";
import { type ToyImageType } from "./type";

export const getToyImageProp = (toyImage: ToyImageType, highlight?: boolean): ToyImageProps => {
  return {
    ...responsiveSquareImageProps,
    src: toyImage.src,
    toyStatus: toyImage.toyStatus,
    borderColor: highlight ? "theme.yellow.70" : "",
    borderWidth: "0.5px",
    borderRadius: "0.5rem",
  };
};

export const getToyImageBoxProp = (): BoxProps => {
  return responsiveSquareBoxProps;
};
