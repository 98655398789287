import { type MutationResult, type QueryResult } from "@apollo/client";
import { type Exact } from "@/gql/generated/graphql.gen";

/* eslint-disable @typescript-eslint/no-explicit-any -- any: Type of each queries */
export const isQueryCompleted = (resultQueries: Array<QueryResult<any, Exact<any>>>): boolean =>
  resultQueries.every((resultQuery) => resultQuery.called && !resultQuery.loading && resultQuery.data !== undefined);

export const isMutationCompleted = (resultMutations: Array<MutationResult<any>>): boolean =>
  resultMutations.every(
    (resultMutation) => resultMutation.called && !resultMutation.loading && resultMutation.data !== undefined
  );

export const isLoading = (resultQueries: Array<QueryResult<any, Exact<any>>>): boolean =>
  resultQueries.some((resultQuery) => resultQuery.loading);
/* eslint-enable @typescript-eslint/no-explicit-any */
