import { type VFC } from "react";
import { Typography } from "@/components/essentials/typography";
import { Flex, Image } from "@chakra-ui/react";
import { Box } from "@/components/essentials/box";
import { planEmptyBoxTestId } from "./const";
import Planner from "~/assets/images/planner.svg";

export const PlanEmptyBox: VFC = () => {
  return (
    <Flex
      data-testid={planEmptyBoxTestId}
      flexDir="column"
      w="100%"
      justifyContent="center"
      alignItems="center"
      bgColor="background.main"
      py="3"
    >
      <Image
        src={Planner}
        alt="プランナー"
        bgColor="mono.light.100"
        border="1px"
        borderColor="border.main"
        p="1"
        borderRadius="50%"
      />
      <Box textAlign="center">
        <Typography variant="display3" mb="1">
          おもちゃをプラン中です
        </Typography>

        <Typography variant="text4" color="gray.main">
          おもちゃ発送後、こちらにプラン内容が表示されます
        </Typography>
      </Box>
    </Flex>
  );
};
