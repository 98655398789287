import { Box } from "@/components/essentials/box";
import { type FC } from "react";
import { ToyImageList } from "@/components/fragments/toy";
import { Button } from "@/components/essentials/button";
import { type ToyImageType } from "@/components/fragments/toy/type";
import { planCardButtonTestId } from "./const";

import Link, { type LinkProps } from "next/link";

type PlanCardType = "normal" | "add";

export interface PlanCardProps {
  readonly type: PlanCardType;
  readonly toyImages: ToyImageType[];
  readonly planDetailHref: LinkProps["href"];
}

export const PlanCard: FC<PlanCardProps> = ({ toyImages, type, planDetailHref }) => {
  return (
    <Box textAlign="right" bgColor="mono.light.100" p="1rem">
      <ToyImageList toyImages={toyImages} />
      <Button as={Link} href={planDetailHref} styleVariant="secondary" mt="1.5rem" data-testid={planCardButtonTestId}>
        {type === "normal" ? "プランの内容を確認する" : "追加分のプランの内容を確認する"}
      </Button>
    </Box>
  );
};
