import { SimpleGrid } from "@chakra-ui/react";
import { type FC } from "react";
import { ToyImage } from "@/components/fragments/toy/ToyImage";
import { toyImageScrollLinkId, toyImageTestId } from "./const";
import { getToyImageBoxProp, getToyImageProp } from "./module";
import { Box } from "@/components/essentials/box";
import { Link as ScrollLink } from "react-scroll";
import { type ToyImageType } from "./type";
import Link from "next/link";

export interface ToyImageListProps {
  readonly toyImages: ToyImageType[];
  readonly highlight?: boolean;
}
const columns = 3;

export const ToyImageList: FC<ToyImageListProps> = (props) => {
  const { toyImages, highlight = false } = props;

  return (
    <SimpleGrid
      columns={columns}
      spacing="0.5rem"
      bg="background.main"
      borderRadius="0.5rem"
      p="0.5rem"
      gridTemplateColumns={`repeat(${columns}, 1fr)`}
      gridTemplateRows={`repeat(${Math.floor((toyImages.length - 1) / columns) + 1}, 1fr)`}
    >
      {toyImages.map((toyImage, index) => {
        const toyImageProps = getToyImageProp(toyImage, highlight);
        if (toyImage.scrollLinkProps !== undefined) {
          return (
            <Box key={index} {...getToyImageBoxProp()} data-testid={toyImageTestId}>
              <ScrollLink key={index} {...toyImage.scrollLinkProps} data-testid={toyImageScrollLinkId}>
                <ToyImage {...toyImageProps} />
              </ScrollLink>
            </Box>
          );
        } else if (toyImage.link !== undefined) {
          return (
            <Box key={index} {...getToyImageBoxProp()} data-testid={toyImageTestId}>
              <Link href={toyImage.link} passHref>
                <ToyImage {...toyImageProps} />
              </Link>
            </Box>
          );
        } else {
          return (
            <Box key={index} {...getToyImageBoxProp()} data-testid={toyImageTestId}>
              <ToyImage {...toyImageProps} />
            </Box>
          );
        }
      })}
    </SimpleGrid>
  );
};
