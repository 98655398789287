/**
 * 未着手
 */
export const SHIPMENT_STATUS_NOT_STARTED = "347e3fdc-2a39-481a-8216-a047081eee1f";

/** プラン中 */
export const SHIPMENT_STATUS_IN_PROGRESS_PLAN = "015f02d3-046a-4dce-9385-dafc3fa18b13";

/** レビュー待ち */
export const SHIPMENT_STATUS_WAITING_FOR_REVIEW = "1c4225c7-2894-466c-bf07-22f3922052be";

/** プラン済み */
export const SHIPMENT_STATUS_COMPLETE_PLAN = "b8dee130-16a8-4f0c-8cab-9f6ece470ea4";

/** 顧客確認依頼 */
export const SHIPMENT_STATUS_CUSTOMER_CONFIRM_REQUESTED = "ad8badb2-a6bc-47ed-bc2a-b780861b934b";

/** 顧客確認中 */
export const SHIPMENT_STATUS_CUSTOMER_CONFIRMING = "06642864-e0a2-aebe-12f2-8add832d74de";

/** 発送保留 */
export const SHIPMENT_STATUS_RESERVED = "2442a45d-d7a4-4fd0-a967-bd4ac8acd164";

/** 発送済み */
export const SHIPMENT_STATUS_COMPLETE_SHIPMENT = "9c27f1b0-a3a1-4243-8915-cc33d927decf";

/** 返却申請中 */
export const SHIPMENT_STATUS_RETURN_REQUESTED = "a51ec23d-6d50-499c-8bf1-99917bf66c46";

/** 返却申請済み */
export const SHIPMENT_STATUS_COMPLETE_RETURN_REQUESTED = "4c4f34fe-3afb-43bd-ab97-843fdc2a554e";

/** 返却確認中 */
export const SHIPMENT_STATUS_RETURN_CONFIRMING = "3621d1b0-9bd5-4af8-876c-541f98546c1a";

/** 返却確認済み */
export const SHIPMENT_STATUS_COMPLETE_RETURN_CONFIRMING = "2751d77d-a6f7-464a-ab71-acf3469d73e3";
