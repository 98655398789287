/** 返却 */
export const SHIPMENT_TOY_STATUS_RETURN = "ccfd1cd3-c15c-4d39-8043-2691ad02c02d";

/** 継続 */
export const SHIPMENT_TOY_STATUS_CONTINUATION = "ceeb03ff-7b04-4ea6-864d-edd5d440bc0e";

/** 購入 */
export const SHIPMENT_TOY_STATUS_PURCHASE = "ffc2b064-244c-451e-9e12-937fd597ca0e";

/** 不備 */
export const SHIPMENT_TOY_STATUS_DEFECTIVE = "9613cd84-50e5-4883-82b4-100f348f8e5d";

/** 非運用 */
export const SHIPMENT_TOY_STATUS_NON_OPERATION = "7d66159b-31a3-40a9-87d7-fae3439dd0c7";
