import { useToast } from "@/components/essentials/toast";
import { ApolloErrorToast } from "@/components/fragments/apollo/error";
import { type ApolloError } from "@apollo/client";
import { useEffect } from "react";

interface Option {
  testid?: string;
}

export const useApolloError = (apolloError: ApolloError | undefined, option?: Option): void => {
  const toast = useToast();

  const testid = option?.testid;
  useEffect(() => {
    if (apolloError !== undefined && !toast.isActive(apolloError.message)) {
      toast({
        id: apolloError.message,
        render: () => <ApolloErrorToast errors={apolloError} testid={testid} />,
      });
    }
  }, [apolloError, testid, toast]);
};
