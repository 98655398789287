import { type ReactNode, type FC, useMemo } from "react";
import { Tag as ChakraTag, type TagProps as ChakraTagProps } from "@chakra-ui/react";
import { type AliasColorScheme } from "@/styles/themes/colors/alias";
import { type TagSize, type TagVariant, type TagBreakpointsSize } from "./type";
import { injectTypographyVariant } from "@/components/essentials/typography/variant";
import { type DisplayVariant } from "@/components/essentials/typography/type";
import { type Breakpoints } from "@/styles/themes/breakpoints";
import { injectTagSize } from "./variant";
import { tagTestId } from "./const";

export interface TagProps extends Omit<ChakraTagProps, "colorScheme" | "size"> {
  readonly colorScheme: AliasColorScheme;
  readonly variant: TagVariant;
  readonly children: ReactNode;
  readonly typography?: DisplayVariant | Record<Breakpoints, DisplayVariant>;
  readonly size?: TagSize | TagBreakpointsSize;
}

export const Tag: FC<TagProps> = ({
  children,
  variant,
  colorScheme,
  typography = "display7",
  size = "md",
  ...rest
}) => {
  const typographyProps = useMemo(() => injectTypographyVariant(typography), [typography]);
  const tagSizeProps = useMemo(() => injectTagSize(size), [size]);
  return (
    <ChakraTag
      variant={variant}
      colorScheme={colorScheme}
      borderRadius="0.3125rem"
      {...typographyProps}
      {...tagSizeProps}
      data-testid={tagTestId}
      {...rest}
    >
      {children}
    </ChakraTag>
  );
};
