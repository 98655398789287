import { Box, type BoxProps } from "@/components/essentials/box";
import { Typography } from "@/components/essentials/typography";
import { UnorderedList } from "@chakra-ui/react";
import { ListItem } from "@/components/essentials/list/ListItem";
import { type FC } from "react";
import { planNoteTestId } from "./const";
import { type PlanNoteFieldsFragment } from "./__gql__/plan-note-fragment.gen";

export interface PlanNoteProps extends BoxProps {
  readonly planNoteFields: PlanNoteFieldsFragment;
}

export const PlanNote: FC<PlanNoteProps> = ({ planNoteFields, ...rest }) => {
  return (
    <Box data-testid={planNoteTestId} {...rest}>
      <Typography variant="display3" mb="4" color="secondary.main">
        保護者の方へ ※必ずお読みください
      </Typography>
      <Box borderRadius="0.5rem" p="4" bgColor="mono.light.100">
        <UnorderedList>
          {planNoteFields.noteOfPlanSheet.map((note, index) => (
            <ListItem key={index}>{note}</ListItem>
          ))}
        </UnorderedList>
      </Box>
    </Box>
  );
};
