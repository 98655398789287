import { Box } from "@/components/essentials/box";
import { type FC } from "react";
import { PlanCard } from "@/components/fragments/plan/plan-card/PlanCard";
import { Typography } from "@/components/essentials/typography";
import { formatAsiaTokyoDateToString } from "@/modules/utils/date";
import { lendingTypographyTestId, planCardContainerTestId, shipmentDateTypographyTestId } from "./const";
import { type ShipmentPlan } from "@/components/fragments/plan/type";

export interface PlanCardContainerProps {
  readonly shipmentPlan: ShipmentPlan;
}

export const PlanCardContainer: FC<PlanCardContainerProps> = ({ shipmentPlan }) => {
  return (
    <Box
      bgColor="mono.light.100"
      p="1rem"
      borderRadius="0.5rem"
      data-testid={`${planCardContainerTestId}${shipmentPlan.uuid}`}
    >
      {shipmentPlan.isLending && (
        <Typography variant="display6" color="theme.yellow.main" data-testid={lendingTypographyTestId}>
          レンタル中のおもちゃ
        </Typography>
      )}
      <Typography variant="display6" data-testid={shipmentDateTypographyTestId}>
        {formatAsiaTokyoDateToString(shipmentPlan.shippedAt, "yyyy年MM月")} 発送のおもちゃ
      </Typography>
      <PlanCard
        toyImages={shipmentPlan.toyThumbnailImages}
        type="normal"
        planDetailHref={shipmentPlan.planDetailHref}
      />
      {shipmentPlan.additionalShipments.map((additionalShipment) => (
        <PlanCard
          key={additionalShipment.uuid}
          toyImages={additionalShipment.toyThumbnailImages}
          type="add"
          planDetailHref={additionalShipment.planDetailHref}
        />
      ))}
    </Box>
  );
};
