import { safeGet } from "@/modules/utils/safe-get";
import { type TagProps } from "@/components/essentials/tag";
import { type TagBreakpointsSize, type TagSize } from "./type";

interface TagReturnProps {
  readonly py: TagProps["py"];
  readonly px: TagProps["px"];
}

type TagSizeValue = Record<TagSize, TagReturnProps>;

const tagSize: TagSizeValue = {
  sm: {
    py: "0.125rem",
    px: "0.3125rem",
  },
  md: {
    py: "0.3125rem",
    px: "0.3125rem",
  },
  lg: {
    py: "0.3125rem",
    px: "0.3125rem",
  },
};

const switchTagSize = (size: TagSize): TagReturnProps => {
  return safeGet(tagSize, size);
};

export const injectTagSize = (size: TagSize | TagBreakpointsSize): TagReturnProps => {
  if (typeof size === "string") {
    return switchTagSize(size);
  } else {
    return {
      py: {
        sm: switchTagSize(size.sm).py as string,
        md: switchTagSize(size.md).py as string,
        lg: switchTagSize(size.lg).py as string,
      },
      px: {
        sm: switchTagSize(size.sm).px as string,
        md: switchTagSize(size.md).px as string,
        lg: switchTagSize(size.lg).px as string,
      },
    };
  }
};
