import { isNonNullable } from "@/modules/utils/predicate";
import { type PlanCardContainerFragmentFieldsFragment } from "./__gql__/plan-card-container-fragment.gen";
import { datadogLogs } from "@datadog/browser-logs";
import { type AdditionalShipmentPlan, type ShipmentPlan, isShipmentPlan } from "@/components/fragments/plan/type";
import {
  SHIPMENT_STATUS_COMPLETE_RETURN_CONFIRMING,
  SHIPMENT_STATUS_COMPLETE_RETURN_REQUESTED,
  SHIPMENT_STATUS_COMPLETE_SHIPMENT,
  SHIPMENT_STATUS_RETURN_CONFIRMING,
  SHIPMENT_STATUS_RETURN_REQUESTED,
} from "@/configs/uuids/shipments/statuses";
import { type LinkProps } from "next/link";
import { SHIPMENT_TYPES_ADD_TO } from "@/configs/uuids/shipments/shipment-type/types";
import { type ToyImageType } from "@/components/fragments/toy/type";
import { pagesPath } from "@/pathpida/generated/$path";
import { toyDetailId } from "@/components/fragments/toy/const";

export type Shipment = PlanCardContainerFragmentFieldsFragment;
export type AdditionalShipment = PlanCardContainerFragmentFieldsFragment["additionalShipments"][0];

const viewableShipmentStatuses: readonly string[] = [
  SHIPMENT_STATUS_COMPLETE_SHIPMENT,
  SHIPMENT_STATUS_RETURN_REQUESTED,
  SHIPMENT_STATUS_COMPLETE_RETURN_REQUESTED,
  SHIPMENT_STATUS_RETURN_CONFIRMING,
  SHIPMENT_STATUS_COMPLETE_RETURN_CONFIRMING,
];

const lendingShipmentStatuses: readonly string[] = [
  SHIPMENT_STATUS_COMPLETE_SHIPMENT,
  SHIPMENT_STATUS_RETURN_REQUESTED,
];

const isShipment = (shipment: Shipment | AdditionalShipment | undefined): shipment is Shipment => {
  if (shipment === undefined) {
    return false;
  }
  return Object.prototype.hasOwnProperty.call(shipment, "additionalShipments");
};

const isViewableShipment = (shipment: Shipment | AdditionalShipment | undefined): boolean => {
  const shipmentStatusUuid = shipment?.shipmentStatus?.uuid;
  if (!isNonNullable(shipmentStatusUuid)) {
    return false;
  }

  // AdditionalShipmentのときはshippedAtのnullを許す
  // 理由：Madras-APIの制約で、AdditionalShipmentのshippedAtが取得できないから
  if (isShipment(shipment)) {
    if (!isNonNullable(shipment.shippedAt)) {
      // 通常発送 かつ 発送完了以降 で shippedAt が null の場合は Madras のデータが不正
      if (viewableShipmentStatuses.includes(shipmentStatusUuid)) {
        const errorMessage = `Inconsistent madras data: shippedAt is null or undefined when normalShipment and after SHIPMENT_STATUS_COMPLETE, shipmentUuid=${shipment.uuid}`;
        datadogLogs.logger.warn(errorMessage, { ortegaData: { shipment } });
      }
      return false;
    }

    if (shipment.shipmentType?.uuid === SHIPMENT_TYPES_ADD_TO) {
      return false;
    }
  }

  return viewableShipmentStatuses.includes(shipmentStatusUuid);
};

type getPlanDetailHrefFunction = (shipmentId: string | number) => LinkProps["href"];

const convertShipmentPlan = (
  shipment: Shipment | AdditionalShipment,
  getPlanDetailHref: getPlanDetailHrefFunction
): ShipmentPlan | AdditionalShipmentPlan => {
  const baseShipment = {
    uuid: shipment.uuid,
    planDetailHref: getPlanDetailHref(shipment.uuid),
    toyThumbnailImages: getToyThumbnailImages(shipment),
  };

  if (!isShipment(shipment)) {
    return baseShipment;
  }

  /* istanbul ignore if */
  if (!isNonNullable(shipment.shippedAt)) {
    throw new Error(
      `unreachable code: shippedAt is null or undefined when normalShipment and after status SHIPMENT_STATUS_COMPLETE, shipmentUuid=${shipment.uuid}`
    );
  }

  return {
    ...baseShipment,
    shippedAt: shipment.shippedAt,
    isLending: isLending(shipment),
    additionalShipments: shipment.additionalShipments
      .filter((shipment) => isViewableShipment(shipment))
      .map((shipment) => convertShipmentPlan(shipment, getPlanDetailHref)),
  };
};

export const getViewableShipmentPlans = (
  shipments: Shipment[] | undefined,
  option: { getPlanDetailHref: getPlanDetailHrefFunction }
): ShipmentPlan[] | undefined => {
  return shipments
    ?.filter((shipment) => isViewableShipment(shipment))
    .map((shipment) => convertShipmentPlan(shipment, option.getPlanDetailHref))
    .filter(isShipmentPlan);
};

export const isLending = (shipment: Shipment | AdditionalShipment): boolean => {
  const shipmentStatusUuid = shipment.shipmentStatus?.uuid;
  if (!isNonNullable(shipmentStatusUuid)) {
    return false;
  }
  return lendingShipmentStatuses.includes(shipmentStatusUuid);
};

const getToyThumbnailImages = (shipment: Shipment | AdditionalShipment): ToyImageType[] => {
  return shipment.shipmentToys.map((toys, index) => ({
    src: toys.toy.toyImages[0]?.thumbnailImage.pathFixed256,
    link: pagesPath.plans.detail._shipmentId(shipment.uuid).$url({ query: { toyDetailId: toyDetailId(index) } }),
  }));
};
