import { type BoxProps, type ImageProps } from "@chakra-ui/react";

export const toyImageTestId = "components/fragments/toy/ToyImage";
export const toyImageScrollLinkId = "components/fragments/toy/ToyImage-ScrollLink";

export const responsiveSquareImageProps: Pick<ImageProps, "position" | "width" | "height" | "objectFit"> = {
  position: "absolute",
  width: "100%",
  height: "100%",
  objectFit: "contain",
};

export const responsiveSquareBoxProps: Pick<BoxProps, "height" | "pb" | "position"> = {
  height: 0,
  pb: "100%",
  position: "relative",
};

export const toyDetailIdPrefix = "toy-detail-id-index-";
export const toyDetailId = (index: number): string => `${toyDetailIdPrefix}${index.toString()}`;

export const toyCardTestId = "components/fragments/toy/ToyCard";
export const toySelectableCardTestId = "components/fragments/toy/ToySelectableCard";
