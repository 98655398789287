import { type LinkProps } from "next/link";
import { type ToyImageType } from "@/components/fragments/toy/type";

export interface ShipmentPlan {
  readonly uuid: string;
  readonly toyThumbnailImages: ToyImageType[];
  readonly shippedAt: string;
  readonly isLending: boolean;
  readonly additionalShipments: AdditionalShipmentPlan[];
  readonly planDetailHref: LinkProps["href"];
}

// shippedAt を Omit しているのは、 Madras API の制約で additionalShipments の shippedAt が取得できないため
export type AdditionalShipmentPlan = Omit<ShipmentPlan, "shippedAt" | "isLending" | "additionalShipments">;

export const isShipmentPlan = (plan: ShipmentPlan | AdditionalShipmentPlan): plan is ShipmentPlan => {
  return Object.prototype.hasOwnProperty.call(plan, "additionalShipments");
};
